/* eslint-disable */

const useLambda = () => {
  const sendContact = async (data) => {
    var raw = JSON.stringify({
      name: data.name,
      email: data.email,
      message: data.message,
      contact_type: data.contact_type,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    let hasError = false;

    const res = await fetch(
      "https://ck24ffazo53wu3cpu73psmykmm0ebejn.lambda-url.us-east-1.on.aws/",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        return JSON.parse(result);
      })
      .catch((error) => console.log("error", error));

    return {
      status: !hasError,
      message: !hasError ? "Thank you for getting in touch" : "Please try again.",
    };
  };

  const sendContacts23 = async (data) => {
    var raw = JSON.stringify({
      name: data.name,
      email: data.email,
      message: data.phone,
      contact_type: data.contact_type,
    });

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
    };

    let hasError = false;

    const res = await fetch(
      "https://ck24ffazo53wu3cpu73psmykmm0ebejn.lambda-url.us-east-1.on.aws/",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        return JSON.parse(result);
      })
      .catch((error) => console.log("error", error));

    return {
      status: !hasError,
      message: !hasError ? "Thank you for getting in touch" : "Please try again.",
    };
  };

  return {
    sendContact,
    sendContacts23,
  };
};

export default useLambda;
