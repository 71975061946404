import React, { useEffect, useRef, useState } from "react";
import { NavBar, VideoFull, Shapes } from "./../components";
import {
  Home,
  StructureData,
  UnstructureData,
  DataIActivator,
  ProductSolution,
  Contact,
  DataCurator,
} from "./MainPages";

import { HomeNabShow } from "./NabShowPages";

function MainPage() {
  const [activeSection, setActiveSection] = useState("home");
  const homeRef = useRef();
  const datai = useRef();
  const product = useRef();
  const contactRef = useRef();

  useEffect(() => {}, []);

  const handleSelectedSection = (item) => {
    switch (item) {
      case "home":
        homeRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "daiTa":
        datai.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "product":
        product.current.scrollIntoView({ behavior: "smooth" });
        break;
      case "contact":
        contactRef.current.scrollIntoView({ behavior: "smooth" });
        break;
      default:
        break;
    }
    if (item !== "") {
      setActiveSection(item);
    }
  };
  const onClickContact = () => {
    contactRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const onScrollToHome = () => {
    homeRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    const homeh = homeRef.current.getBoundingClientRect().height;
    const dataih = datai.current.getBoundingClientRect().height;
    const producth = product.current.getBoundingClientRect().height;
    if (window.scrollY <= homeh) {
      setActiveSection("home");
    } else if (window.scrollY <= homeh + dataih) {
      setActiveSection("daiTa");
    } else if (window.scrollY <= homeh + dataih + producth) {
      setActiveSection("product");
    } else {
      setActiveSection("contact");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="w-screen h-screen">
      <VideoFull
        src={"https://all5data-assets.s3.amazonaws.com/01-bg-video.mp4"}
        type={"video/mp4"}
      />
      <div className="bg-[#000] opacity-80 fixed w-screen h-screen"></div>
      {/* <Shapes /> */}
      <NavBar
        activeSection={activeSection}
        onSelectMenu={(i) => handleSelectedSection(i)}
      />
      <div>
        <div ref={homeRef}>
          {/* <div className="relative">
            <HomeNabShow
              onScrollToHome={() => onScrollToHome()}
              sendContact={() => onClickContact()}
            />
          </div> */}
          <div className="relative">
            <Home onClickContact={() => onClickContact()} />
          </div>
          <div className="relative">
            <StructureData />
          </div>
          <div className="relative">
            <UnstructureData />
          </div>
        </div>
        <div ref={datai}>
          <div className="relative">
            <DataIActivator onClickContact={() => onClickContact()} />
          </div>
          <div className="relative">
            <DataCurator onClickContact={() => onClickContact()} />
          </div>
        </div>

        <div className="relative" ref={product}>
          <ProductSolution onClickContact={() => onClickContact()} />
        </div>
        <div className="relative" ref={contactRef}>
          <Contact />
        </div>
      </div>
    </div>
  );
}

export default MainPage;
