import React, { useState } from "react";
import { ButtonComponent, ListAnimed } from "../../components";
import home_image from "../../assets/home_asset.png";
import hsm1 from "../../assets/ic_hsm1.svg";
import hsm2 from "../../assets/ic_hsm2.svg";
import hsm3 from "../../assets/ic_hsm3.svg";
import check from "../../assets/check.svg";
const DataIActivator = ({ onClickContact = () => {} }) => {
  return (
    <div className="w-screen lg:h-screen p-2 lg:p-0 z-20">
      <div className="flex flex-col justify-center items-center text-white h-full">
        <div className="flex flex-col container justify-center  mt-28">
          <span className="text-[28px]">Enhanced HSM</span>
          <div className="grid lg:grid-cols-3 gap-5">
            <div className="flex items-center rounded-lg bg-[#FFFFFF1A] p-3 gap-3">
              <div className="">
                <img src={hsm1} alt="" />
              </div>
              <div className="flex-1 flex flex-col">
                <span className="text-[22px]">
                  Migrate, Catalog and Curate Your Data
                </span>
              </div>
            </div>

            <div className="flex items-center rounded-lg bg-[#FFFFFF1A] p-3 gap-3">
              <div className="">
                <img src={hsm2} alt="" />
              </div>
              <div className="flex-1 flex flex-col">
                <span className="text-[22px]">
                  Search for Context and Tag Your File
                </span>
              </div>
            </div>
            <div className="flex items-center rounded-lg bg-[#FFFFFF1A] p-3 gap-3">
              <div className="flex items-center ">
                <img src={hsm3} alt="" />
              </div>
              <div className="flex-1 flex flex-col ">
                <span className="text-[22px]">
                  Archive and Continuous Data Protection
                </span>
              </div>
            </div>
          </div>
          <div className="flex mt-10 flex-col lg:flex-row">
            <div className=" flex-1">
              <p className="text-[28px] font-semibold">
                Move, Catalog, Tag, Archive
              </p>
              <div className="mt-2 flex flex-col gap-5">
                <div className="flex rounded-lg bg-[#FFFFFF1A] p-3 gap-3 items-center">
                  <div className="">
                    <img src={check} alt="" />
                  </div>
                  <div className="flex-1 flex flex-col ">
                    <span className="text-[16px]">
                      We can help you move or consolidate your data
                    </span>
                  </div>
                </div>
                <div className="flex rounded-lg bg-[#FFFFFF1A] p-3 gap-3 items-center">
                  <div className="">
                    <img src={check} alt="" />
                  </div>
                  <div className="flex-1 flex flex-col">
                    <span className="text-[16px]">
                      We help index your file contents better data gets more
                      predictive AI
                    </span>
                  </div>
                </div>
                <div className="flex rounded-lg bg-[#FFFFFF1A] p-3 gap-3 items-center">
                  <div className="">
                    <img src={check} alt="" />
                  </div>
                  <div className="flex-1 flex flex-col">
                    <span className="text-[16px]">
                      Continuous data protection and life cycle data management
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex-1 flex items-center justify-center">
              <img src={home_image} alt="" className="h-80" />
            </div>
          </div>
        </div>
        <div className="flex flex-row container items-center justify-center ">
          <ButtonComponent
            text="SIGN UP & JOIN EARLY ACCESS"
            onClick={onClickContact}
          />
        </div>
      </div>
    </div>
  );
};
export default DataIActivator;
