/* eslint-disable  */
import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";

class ChangingProgressProvider extends React.Component {
  static defaultProps = {
    interval: 1000
  };

  state = {
    valuesIndex: 0
  };

  componentDidMount() {
    setInterval(() => {
      this.setState({
        valuesIndex: (this.state.valuesIndex + 1) % this.props.values.length
      });
    }, this.props.interval);
  }

  render() {
    return this.props.children(this.props.values[this.state.valuesIndex]);
  }
}

const CircularProgress = ({ percentage, name }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <ChangingProgressProvider values={[percentage]}>
        {percentage => (
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            styles={buildStyles({
              pathTransitionDuration: 0.15,
              pathColor: "#29ABE2",
              trailColor: "#FFFFFF"
            })}
          />
        )}
      </ChangingProgressProvider>
      <span className="text-xs pt-2 text-center">{name}</span>
    </div>
  );
};

export default CircularProgress;
