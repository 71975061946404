import React, { useState, useEffect } from "react";
import { ButtonComponent, ListAnimed } from "../../components";
import solution from "../../assets/solution.png";
import ai_ml from "../../assets/ai_ml.png";
import img_data from "../../assets/data.png";
import ic_tab_storage from "../../assets/ic_tab_storage.svg";
import ic_tab_data from "../../assets/ic_tab_data.svg";
import ic_tab_ai from "../../assets/ic_tab_ai.svg";

const ProductSolution = ({onClickContact = () => {}}) => {
  const [selected, setSelected] = useState("storage");
  const data = ["storage", "ai", "data"];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Your code here will run every 2 seconds
  //     setSelected((preSelected) => {
  //       const i = data.findIndex((d) => d === preSelected);
  //       const s = i === data.length - 1 ? 0 : i + 1;
  //       return data[s];
  //     });
  //   }, 2000); // Interval set to 2000 milliseconds (2 seconds)

  //   // Clear the interval when the component unmounts to avoid memory leaks
  //   return () => clearInterval(interval);
  // }, []);

  const setSelectedMenu = (menu) => {
    setSelected(menu);
  };

  return (
    <div className="w-screen lg:h-screen p-0 lg:p-2   z-20">
      <div className="flex flex-col justify-center items-center text-white h-full">
        <div className="flex flex-col container justify-center  mt-28">
          <span className="text-[28px] mb-4">Product Solutions</span>
          <div className="flex flex-col justify-center lg:justify-start lg:flex-row rounded-lg bg-[#FFFFFF1A] p-3 gap-3">
            <div className={`flex justify-center ${selected === "storage" ? "flex-1" : ""}`}>
              <div className="flex items-center justify-center">
                <button className="relative flex flex-col items-center justify-center flex-1"  onClick={() => setSelectedMenu("storage")}>
                  <img src={solution} alt="" className="h-full" />
                  <p
                    className={`absolute bottom-2 flex justify-center gap-2 ${
                      selected === "storage" ? "text-[#29ABE2]" : ""
                    }`}
                  >
                    <img src={ic_tab_storage} alt="" />
                    Storage Solutions
                  </p>
                </button>
              </div>
              <div
                className={`flex-1 flex flex-col justify-center absolute animate-fade-right lg:relative p-16 lg:px-8 ${
                  selected === "storage" ? "" : "lg:hidden"
                }`}
              >
                <p>Storage</p>{" "}
                <ul className="text-xs list-disc list-inside">
                  Software
                  <li className="pl-6">Enhanced HSM</li>
                </ul>
                <span>Hardware</span>
                <ul className="text-xs list-disc list-inside">
                  On Prem Hardware Support
                  <li className="pl-6">Servers</li>
                  <li className="pl-6">Storage</li>
                  <li className="pl-6">Networking</li>
                </ul>
                <ul className="text-xs list-disc list-inside">
                  Cloud Platform Support
                  <li className="pl-6">AWS </li>
                  <li className="pl-6">GCP</li>
                  <li className="pl-6">Azure</li>
                </ul>
              </div>
            </div>
            <div className={`flex justify-center ${selected === "ai" ? "flex-1" : ""}`}>
              <div className="flex items-center justify-center">
                <button className="relative flex flex-col items-center justify-center flex-1" onClick={() => setSelectedMenu("ai")}>
                  <img src={ai_ml} alt="" className="h-full" />
                  <p
                    className={`absolute bottom-2 flex justify-center gap-2 ${
                      selected === "ai" ? "text-[#29ABE2]" : ""
                    }`}
                  >
                    <img src={ic_tab_ai} alt="" />
                    AI & ML Solutions
                  </p>
                </button>
              </div>
              <div
                className={`flex-1 flex flex-col justify-center p-16 animate-fade-right lg:px-8 absolute lg:relative ${
                  selected === "ai" ? "" : "lg:hidden"
                }`}
              >
                <p>AI & ML Solutions </p>{" "}
                <ul className="text-xs list-disc list-inside">
                  AI Vendors
                  <li className="pl-6">NVIDIA</li>
                  <li className="pl-6">Furiosa AI</li>
                </ul>
                <span className="text-xs">Hardware</span>
                <ul className="text-xs list-disc list-inside">
                  Solution Expertise
                  <li className="pl-6">Video Models (Various) </li>
                  <li className="pl-6">Vision Models (Various)</li>
                  <li className="pl-6">OCR Models (Various)</li>
                  <li className="pl-6">LLM’s (Various)</li>
                </ul>
              </div>
            </div>
            <div className={`flex justify-center ${selected === "data" ? "flex-1" : ""}`}>
              <div  className="flex items-center justify-center">
                <button className="relative flex flex-col items-center justify-center flex-1"  onClick={() => setSelectedMenu("data")}>
                  <img src={img_data} alt="" className="h-full" />
                  <p
                    className={`absolute bottom-2 flex justify-center gap-2 ${
                      selected === "data" ? "text-[#29ABE2]" : ""
                    }`}
                  >
                    <img src={ic_tab_ai} alt="" />
                    Data Center Solutions
                  </p>
                </button>
              </div>
              <div
                className={`flex-1 flex flex-col justify-center p-16 lg:px-8 animate-fade-right absolute lg:relative ${
                  selected === "data" ? "" : "lg:hidden"
                }`}
              >
                <p>Data Center </p>{" "}
                <ul className="text-xs list-disc list-inside">
                  <li className="pl-6">
                    White Box Turnkey OEM and ODM Solutions{" "}
                  </li>
                  <li className="pl-6">
                    Datacenter Modular Hardware Systems (DC - MHS)
                  </li>
                  <li className="pl-6">CXL Expertise - Memory Servers</li>
                  <li className="pl-6">Sustainable Data Center Designs </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row container items-center justify-center mt-8">
          <ButtonComponent text="SIGN UP & JOIN EARLY ACCESS"  onClick={onClickContact}/>
        </div>
      </div>
    </div>
  );
};
export default ProductSolution;
