import React, {useRef, useEffect} from "react";

const VideoFull = ({ src, type }) => {

  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
    }
  }, []); 

  return (
    <div
        className={`w-screen h-screen bg-black fixed`}
      >
        <video loop ref={videoRef} muted autoPlay={true} className="w-screen h-screen">
          <source
            src={src}
            type={type}
          />
        </video>
      </div>
  );
};
export default VideoFull;
