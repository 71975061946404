import React from "react";
import {
  ErrorPage,
  MainPage,
  PageError
} from "../pages";


export const getMainRouter = () => {
  return [
    {
      path: "/",
      element: <MainPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/sc23",
      element: <PageError />,
      errorElement: <ErrorPage />,
    },
  ];
};
