import React, { useState } from "react";
import { ButtonComponent, ListAnimed } from "../../components";
import logo from "../../assets/logo_white.png";
import useLambda from "../../hook/useLambda";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import phone from "../../assets/phone.png";

const Home = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    contact_type: "sc23",
  });
  const [inputError, setInputError] = useState({
    name: "",
    email: "",
    phone: "",
    contact_type: "",
  });

  const onChangeValue = (name, value) => {
    const v = { ...values };
    v[name] = value;
    setValues(v);
  };

  const lambda = useLambda();

  const verifyData = (d) => {
    const ie = {};
    setInputError(ie);

    if (!d.name) {
      ie.name = "Name is required";
      setInputError(ie);
      return {
        status: false,
        message: "Name is required",
      };
    }

    if (!d.email) {
      ie.email = "Email is required";
      setInputError(ie);
      return {
        status: false,
        message: "Email is required",
      };
    }

    if (
      !d.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      ie.email = "Email does not have mail format";
      setInputError(ie);
      return {
        status: false,
        message: "Email does not have mail format",
      };
    }

    if (!d.phone) {
      ie.phone = "Phone is required";
      setInputError(ie);
      return {
        status: false,
        phone: "Phone is required",
      };
    }

    return {
      status: true,
      message: "",
    };
  };

  const confirm = async () => {
    const d = { ...values };
    const resValidate = verifyData(d);
    if (resValidate.status) {
      const res = await lambda.sendContacts23(d);
      if (res.status) {
        setValues({
          name: "",
          email: "",
          phone: "",
          contact_type: "landing",
        });
        toast.success(res.message, {
          position: "top-right",
          autoClose: 5000,
        });
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } else {
      toast.error(resValidate.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="w-screen lg:h-screen   z-20">
      <div className="flex flex-col justify-center items-center text-white h-full">
        <div className="flex flex-col container justify-center items-center mt-28">
          <div className="flex flex-col lg:flex-row justify-center items-center gap-10">
            <div className="flex-1 flex flex-col items-center justify-center  rounded-lg bg-[#FFFFFF1A] p-3 gap-3 w-5/6">
              <img src={logo} alt="" className="h-20" />
              <span className="text-[48px]">
                Preview And Catalog File Contents{" "}
                <span className="text-[48px] text-[#29ABE2]">
                  Like Never Before
                </span>
              </span>

              <span className="text-[32px]">
                For chance to win multiple amazon gift cards daily!
              </span>
            </div>
            <div className="flex-1 lg:relative h-full flex items-center">
              <img src={phone} alt="" className="fixed top-2 hidden lg:inline" />
              <div className="  rounded-lg bg-[#FFFFFF1A] p-3 gap-3 lg:w-5/6 z-10">
                <p className="text-[24px] text-center">Complete to Win!</p>
                <div>
                  <div className="mb-4">
                    <label
                      className="block text-white text-sm font-bold mb-2"
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      id="name"
                      type="text"
                      placeholder="name"
                      value={values.name}
                      onChange={(e) => onChangeValue("name", e.target.value)}
                    />
                    {inputError.name !== "" && (
                      <span className="text-[12px] text-[#FF0000]">
                        {inputError.name}
                      </span>
                    )}
                  </div>

                  <div className="mb-4">
                    <label
                      className="block text-white text-sm font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      id="email"
                      type="email"
                      placeholder="email"
                      value={values.email}
                      onChange={(e) => onChangeValue("email", e.target.value)}
                    />
                    {inputError.email !== "" && (
                      <span className="text-[12px] text-[#FF0000]">
                        {inputError.email}
                      </span>
                    )}
                  </div>

                  <div className="mb-4">
                    <label
                      className="block text-white text-sm font-bold mb-2"
                      htmlFor="phone"
                    >
                      Phone
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                      id="phone"
                      type="text"
                      placeholder="phone"
                      value={values.phone}
                      onChange={(e) => onChangeValue("phone", e.target.value)}
                    />
                    {inputError.phone !== "" && (
                      <span className="text-[12px] text-[#FF0000]">
                        {inputError.phone}
                      </span>
                    )}
                  </div>
                  <ButtonComponent text="Send" onClick={() => confirm()} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Home;
