import React, { useState } from "react";
import { ButtonComponent, ListAnimed } from "../../components";
import logo from "../../assets/logo_white.png";
import facebook from "../../assets/facebook.svg";
import x from "../../assets/x.svg";
import linkedin from "../../assets/linkedin.svg";
import useLambda from "../../hook/useLambda";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
    contact_type: "landing",
  });
  const [inputError, setInputError] = useState({
    name: "",
    email: "",
    message: "",
    contact_type: "",
  });

  const onChangeValue = (name, value) => {
    const v = { ...values };
    v[name] = value;
    setValues(v);
  };

  const lambda = useLambda();

  const verifyData = (d) => {
    const ie = {};
    setInputError(ie);

    if (!d.name) {
      ie.name = "Name is required";
      setInputError(ie);
      return {
        status: false,
        message: "Name is required",
      };
    }

    if (!d.email) {
      ie.email = "Email is required";
      setInputError(ie);
      return {
        status: false,
        message: "Email is required",
      };
    }

    if (
      !d.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      ie.email = "Email does not have mail format";
      setInputError(ie);
      return {
        status: false,
        message: "Email does not have mail format",
      };
    }

    if (!d.message) {
      ie.message = "Message is required";
      setInputError(ie);
      return {
        status: false,
        message: "Message is required",
      };
    }

    return {
      status: true,
      message: "",
    };
  };

  const confirm = async () => {
    const d = { ...values };
    const resValidate = verifyData(d);
    if (resValidate.status) {
      const res = await lambda.sendContact(d);
      if (res.status) {
        setValues({
          name: "",
          email: "",
          message: "",
          contact_type: "landing",
        });
        toast.success(res.message, {
          position: "top-right",
          autoClose: 5000,
        });
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: 5000,
        });
      }
    } else {
      toast.error(resValidate.message, {
        position: "top-right",
        autoClose: 5000,
      });
    }
  };

  return (
    <div className="w-screen lg:h-screen p-0 lg:p-2 z-20">
      <div className="flex flex-col justify-center items-center text-white h-full">
        <div className="flex flex-col container justify-center items-center mt-28">
          <div className="flex flex-col lg:flex-row rounded-lg bg-[#FFFFFF1A] p-3 gap-3 w-5/6">
            <div className="flex-1">
              <div className="mb-4">
                <label
                  className="block text-white text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder=""
                  value={values.name}
                  onChange={(e) => onChangeValue("name", e.target.value)}
                />
                {inputError.name !== "" && (
                  <span className="text-[12px] text-[#FF0000]">
                    {inputError.name}
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-white text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder=""
                  value={values.email}
                  onChange={(e) => onChangeValue("email", e.target.value)}
                />
                {inputError.email !== "" && (
                  <span className="text-[12px] text-[#FF0000]">
                    {inputError.email}
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label
                  className="block text-white text-sm font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
                  id="message"
                  type="text"
                  placeholder=""
                  value={values.message}
                  onChange={(e) => onChangeValue("message", e.target.value)}
                ></textarea>
                {inputError.message !== "" && (
                  <span className="text-[12px] text-[#FF0000]">
                    {inputError.message}
                  </span>
                )}
              </div>
              <div className="flex justify-center">
                <ButtonComponent text="Send" onClick={() => confirm()} />
              </div>
            </div>
            <div className="flex-1 flex flex-col items-center justify-center gap-5">
              <img src={logo} alt="" className="h-20" />
              <span>PO Box 24118, Federal Way, WA 98093 USA</span>
              <span>info@all5data.com</span>
              <span>(253) 951 - 0200</span>
              <div className="flex items-center justify-center gap-3">
                <img src={facebook} alt="" />
                <img src={x} alt="" />
                <img src={linkedin} alt="" />
              </div>
              <div className="flex flex-col items-center">
                <span className="font-bold">Small Business</span>
                <span>Government Contractor CAGE CODE: 9R4P7</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Contact;
