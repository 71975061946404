import React, { useEffect, useRef, useState } from "react";
import { NavBar, VideoFull } from "../components";
import { Home } from "./Sc23Pages";

function Sc23Page() {
  const [activeSection, setActiveSection] = useState("home");
  const homeRef = useRef();
  const contactRef = useRef();

  useEffect(() => {}, []);

  // const handleSelectedSection = (item) => {
  //   switch (item) {
  //     case "home":
  //       homeRef.current.scrollIntoView();
  //       break;
  //     case "gallery":
  //       galleryRef.current.scrollIntoView();
  //       break;
  //     case "services":
  //       servicesRef.current.scrollIntoView();
  //       break;
  //     case "product":
  //       productRef.current.scrollIntoView();
  //       break;
  //     case "cto":
  //       ctoRef.current.scrollIntoView();
  //       break;
  //     case "patner":
  //       patnerRef.current.scrollIntoView();
  //       break;
  //     case "contact":
  //       contactRef.current.scrollIntoView();
  //       break;
  //     default:
  //       break;
  //   }
  //   setActiveSection(item);
  // };
  const onClickContact = () => {
    contactRef.current.scrollIntoView();
  };

  return (
    <div className="w-screen h-screen">
      <VideoFull
        src={"https://all5data-assets.s3.amazonaws.com/01-bg-video.mp4"}
        type={"video/mp4"}
      />
      <div className="bg-[#000] opacity-80 fixed w-screen h-screen"></div>
      <NavBar activeSection={activeSection} onSelectMenu={() => {}} goHomeFirst />
      <div ref={homeRef}>
        <div className="relative">
          <Home onClickContact={() => onClickContact()} />
        </div>
      </div>
    </div>
  );
}

export default Sc23Page;
