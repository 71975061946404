import React from "react";

const ButtonComponent = ({ text, onClick = () =>{} }) => {
  return (
    <button
      className=" bg-[#1F586D]  bg-opacity-50 border-2 font-sans text-[18px] lg:text-[18px] text-white border-[##00AEEF] rounded-lg py-3 px-6 lg:px-28  drop-shadow-[6px_7px_rgba(0,0,0,0.40)]"
      onClick={() => onClick(text)}
    >
      {text}
    </button>
  );
};
export default ButtonComponent;
