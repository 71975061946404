import React, { useState, useEffect } from "react";
import { ListAnimed, CircularProgress } from "../../components";
import ic_book from "../../assets/ic_book.svg";
import ic_communication from "../../assets/ic_communication.svg";
import ic_data from "../../assets/ic_data.svg";
import ic_customer from "../../assets/ic_customer.svg";
import ic_email from "../../assets/ic_email.svg";
import ic_media from "../../assets/ic_media.svg";
import ic_medical from "../../assets/ic_medical.svg";
import ic_scientific from "../../assets/ic_scientific.svg";
import ic_phone from "../../assets/ic_phone.svg";
import ic_social from "../../assets/ic_social.svg";
import ic_text from "../../assets/ic_text.svg";
import ic_website from "../../assets/ic_website.svg";

const UnstructureData = () => {
  const [selected, setSelected] = useState("Email");

  const data = [
    {
      name: "Email",
      icon: ic_email,
    },
    {
      name: "Social Media",
      icon: ic_social,
    },
    {
      name: "Website",
      icon: ic_website,
    },
    {
      name: "Text Files",
      icon: ic_text,
    },
    {
      name: "Media",
      icon: ic_media,
    },
    {
      name: "Mobile Data",
      icon: ic_phone,
    },
    {
      name: "Raw Research Data",
      icon: ic_data,
    },
    {
      name: "Books, Magazine, Blogs",
      icon: ic_book,
    },
    {
      name: "Communications",
      icon: ic_communication,
    },
    {
      name: "Scientific Data",
      icon: ic_scientific,
    },
    {
      name: "Customer-Generated Content",
      icon: ic_customer,
    },
    {
      name: "Medical Records",
      icon: ic_medical,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      // Your code here will run every 2 seconds
      setSelected((preSelected) => {
        const i = data.findIndex((d) => d.name === preSelected);
        const s = i === data.length - 1 ? 0 : i + 1;
        return data[s].name;
      });
    }, 2000); // Interval set to 2000 milliseconds (2 seconds)

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-screen lg:h-screen p-2 lg:p-0 z-20">
      <div className="flex flex-col justify-center items-center text-white h-full">
        <div className="flex flex-col lg:flex-row  container items-center justify-center mt-20">
          <div className=" flex-1">
            <div>
              <p className="text-[28px] font-semibold">
                What Is Unstructured Data?
              </p>
              <div className="flex justify-center mt-2">
                <div className="w-40 p-4">
                  <CircularProgress
                    percentage={90}
                    name="90% of all data is unstructured"
                  />
                </div>
                <div className="flex-1 flex items-center">
                  <ListAnimed
                    data={[
                      "Information that is not organized in a defined data structure",
                      "Comes in many forms - email, text files, social media, books, medical records, raw scientific data, etc...",
                    ]}
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="text-[28px] font-semibold">
                Stop Wasting Time and $$$ Curating Data!
              </p>
              <div className="flex justify-center mt-2">
                <div className="w-40 p-4">
                  <CircularProgress
                    percentage={50}
                    name="More time spent modeling and training your AI"
                  />
                </div>
                <div className="flex-1 flex items-center">
                  <ListAnimed
                    data={[
                      "50% reduction in data curation",
                      "50% reduction in costs to curate",
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10"></div>
          </div>
          <div className=" flex-1  p-8">
            <div className="grid grid-cols-3 gap-4">
              {data.map((d) => {
                return (
                  <div
                    key={d.name}
                    className={`rounded-lg p-2 flex flex-col items-center justify-center ${
                      selected === d.name
                        ? "bg-[#1EAEEF59] animate-pulse"
                        : "bg-[#FFFFFF1A] "
                    }  h-36 lg:h-32`}
                  >
                    <img src={d.icon} alt="" />
                    <span className="mt-2 text-[12px] text-center">
                      {d.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UnstructureData;
