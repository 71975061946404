import React, { useState } from "react";
import all5dataLogo from "../assets/logo.png";
import { useNavigate } from "react-router-dom";

const NavBar = ({ activeSection, onSelectMenu, goHomeFirst = false }) => {
  const navigate = useNavigate();

  const [fullMenuOpen, setFullMenuOpen] = useState(false);
  const goHome = () => {
    navigate("/");
  };

  const selectMenu = (menu) => {
    onSelectMenu(menu);
    setFullMenuOpen(false);
  };

  const openFullMenu = () => {
    setFullMenuOpen(!fullMenuOpen);
  };

  const menu = (
    <>
      <li>
        <button
          className={`block py-2 pl-3 pr-4 ${
            activeSection === "home" ? "text-[#29ABE2]" : "text-black"
          } rounded lg:p-0`}
          onClick={() => (goHomeFirst ? goHome() : selectMenu("home"))}
        >
          Home
        </button>
      </li>
      <li>
        <button
          className={`block py-2 pl-3 pr-4 ${
            activeSection === "daiTa" ? "text-[#29ABE2]" : "text-black"
          } rounded lg:p-0`}
          onClick={() => (goHomeFirst ? goHome() : selectMenu("daiTa"))}
        >
          Enhanced HSM
        </button>
      </li>
      <li>
        <button
          className={`block py-2 pl-3 pr-4 ${
            activeSection === "product" ? "text-[#29ABE2]" : "text-black"
          } rounded lg:p-0`}
          onClick={() => (goHomeFirst ? goHome() : selectMenu("product"))}
        >
          Product Solutions
        </button>
      </li>
      <li>
        <button
          className={` bg-[#29ABE2] text-white px-6 py-2  rounded-lg `}
          onClick={() => (goHomeFirst ? goHome() : selectMenu("contact"))}
        >
          Contact
        </button>
      </li>
    </>
  );

  return (
    <>
      <nav className="bg-[#FFF] fixed flex z-20 right-0 left-0 lg:mx-14 lg:top-12  lg:rounded-lg py-2 lg:px-12 px-4">
        <div className="w-full flex flex-row items-center ">
          <div className="flex-1">
            <button
              className="flex items-center "
              onClick={() => (goHomeFirst ? goHome() : selectMenu("home"))}
            >
              <img
                src={all5dataLogo}
                className="h-8 mr-3"
                alt="Flowbite Logo"
              />
              <div className="flex flex-row  gap-2 text-[28px]">
                <span className="font-bold">Data</span>
                <span className="text-[#29ABE2] font-bold">DRIVEN</span>
              </div>
            </button>
          </div>

          <div className=" flex justify-end">
            <ul className=" flex-row justify-center items-center gap-12 hidden lg:flex">
              {menu}
            </ul>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <button className="lg:hidden" onClick={openFullMenu}>
              {!fullMenuOpen ? (
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.66797 14.6663H20.3346M1.66797 7.99967H20.3346M1.66797 1.33301H20.3346"
                    stroke="#090909"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6654 4.21301L17.7854 0.333008L10.9987 7.11967L4.21203 0.333008L0.332031 4.21301L7.1187 10.9997L0.332031 17.7863L4.21203 21.6663L10.9987 14.8797L17.7854 21.6663L21.6654 17.7863L14.8787 10.9997L21.6654 4.21301Z"
                    fill="black"
                  />
                </svg>
              )}
            </button>
          </div>
        </div>
      </nav>
      <div
        className={`fixed bg-white h-screen w-screen top-0 left-0 z-10 lg:hidden ${
          fullMenuOpen ? "" : "hidden"
        }`}
      >
        <ul className=" w-full flex flex-col items-center py-8 gap-8 justify-center h-full">
          {menu}
        </ul>
      </div>
    </>
  );
};
export default NavBar;
