import React, { useEffect, useRef, useState } from "react";
import { NavBar, VideoFull } from "../components";

function Sc23Page() {
  const [activeSection, setActiveSection] = useState("home");
  const homeRef = useRef();
  const contactRef = useRef();

  useEffect(() => {}, []);

  const onClickContact = () => {
    contactRef.current.scrollIntoView();
  };

  return (
    <div className="w-screen h-screen">
      <VideoFull
        src={"https://all5data-assets.s3.amazonaws.com/01-bg-video.mp4"}
        type={"video/mp4"}
      />
      <div className="bg-[#000] opacity-80 fixed w-screen h-screen"></div>
      <NavBar activeSection={activeSection} onSelectMenu={() => {}} />
      <div ref={homeRef}>
        <div className="relative">
          404
        </div>
      </div>
    </div>
  );
}

export default Sc23Page;
