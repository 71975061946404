import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { getMainRouter } from "./router/Main";

const App = () => {
  const [router, setRouter] = useState([]);
  const [hasRouter, setHasRouter] = useState(false);
  // TODO: esta creando modelos
  useEffect(() => {
    const mainRouters = getMainRouter();
    const defaultRouter = [].concat(mainRouters);
    setRouter(createBrowserRouter(defaultRouter));
    setHasRouter(true);
  }, []);

  return <div>{hasRouter && <RouterProvider router={router} />}</div>;
};

export default App;
