import React, { useState } from "react";
import { ListAnimed } from "../../components";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";

const StructureData = () => {
  const data = [
    {
      name: "",
      zb: 0,
    },
    {
      name: "2010",
      zb: 2,
    },
    {
      name: "2011",
      zb: 5,
    },
    {
      name: "2012",
      zb: 8,
    },
    {
      name: "2013",
      zb: 10,
    },
    {
      name: "2014",
      zb: 12,
    },
    {
      name: "2015",
      zb: 15,
    },
    {
      name: "2016",
      zb: 17,
    },
    {
      name: "2017",
      zb: 25,
    },
    {
      name: "2018",
      zb: 32,
    },
    {
      name: "2019",
      zb: 40,
    },
    {
      name: "2020",
      zb: 62,
    },
  ];

  return (
    <div className="w-screen lg:h-screen p-2 lg:p-0 z-20">
      <div className="flex flex-col justify-center items-center text-white h-full">
        <div className="mt-20 container text-[32px] flex justify-start items-start w-full">
          <span>Structured Data Is Necessary for AI Modeling to Work</span>
        </div>
        <div className="flex flex-col lg:flex-row container items-center justify-center ">
          <div className=" flex-1">
            <p className="text-[48px] font-semibold">
              <span>
                Why Are Companies  Experiencing{" "}
                <span className="text-[#29ABE2]">AI Failure</span>?
              </span>
            </p>
            <div className="mt-10">
              <ListAnimed
                data={[
                  "90% of your data is unstructured - this represents untapped value. (IDC 2023)",
                  "IDC says organizations globally are predicted to generate over 73,000 exabytes of unstructured data in 2023.",
                  "Your unstructured data is business critical at every level of a company, from boardroom strategy to daily operations.",
                ]}
              />
            </div>
          </div>
          <div className=" flex-1 flex flex-col items-center justify-center backdrop-blur p-8">
            <span className="mt-2">
              Volume of Data Created, Captured, Copied, & Consumed Worldwide.
              (2010 - 2020)
            </span>
            <div className="mt-4  hidden lg:inline">
              <LineChart
                width={600}
                height={350}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="name" />
                <YAxis />
                
                <Line type="monotone" dataKey="zb" stroke="#82ca9d" />
              </LineChart>
            </div>

            <div className="mt-4  inline lg:hidden">
              <LineChart
                width={300}
                height={150}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3" />
                <XAxis dataKey="name" />
                <YAxis />
                
                <Line type="monotone" dataKey="zb" stroke="#82ca9d" />
              </LineChart>
            </div>

            <div className="flex flex-row justify-between w-full text-[12px]">
              <span>Data volume in zettabytes.</span>
              <span>Source : <a href="https://www.statista.com/statistics/871513/worldwide-data-created/" target="_blank" className="text-[#29ABE2]" rel="noreferrer">Statista</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StructureData;
