import React, { useState } from "react";
import { ButtonComponent, ListAnimed } from "../../components";
import home_image from "../../assets/homes.png";
import flow from "../../assets/flow.gif";

const DataCurator = ({ onClickContact = () => {} }) => {
  return (
    <div className="w-screen lg:h-screen p-2 lg:p-0 z-20">
      <div className="flex flex-col justify-center items-center text-white h-full">
        <div className="flex flex-col container justify-center  mt-28">
          <span className="text-[28px]">Data Curation Made Simple</span>
          
          <div className="flex items-center mt-10 flex-col lg:flex-row">
            <div className=" flex-1 flex ">
            <img src={flow} alt="" className="h-full" />
            </div>
            <div className=" flex-1 flex ">
              <img src={home_image} alt=""  />
            </div>
          </div>
        </div>
        <div className="flex flex-row container items-center justify-center ">
          <ButtonComponent
            text="SIGN UP & JOIN EARLY ACCESS"
            onClick={onClickContact}
          />
        </div>
      </div>
    </div>
  );
};
export default DataCurator;
