import React, { useEffect, useState } from "react";
import list_active from "../assets/list_active.svg";
import list_inactive from "../assets/list_inactive.svg";

const ListAnimed = ({ data = [] }) => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Your code here will run every 2 seconds
      setSelected((preSelected) =>
        preSelected === data.length - 1 ? 0 : preSelected + 1
      );
    }, 2500); // Interval set to 2000 milliseconds (2 seconds)

    // Clear the interval when the component unmounts to avoid memory leaks
    return () => clearInterval(interval);
  }, []); // Passing an empty dependency array to run this effect only once

  return (
    <div>
      <table>
        <tbody>
          {data.map((d, k) => {
            return (
              <tr className="min-h-10 pb-2" key={k}>
                <td className="w-10 ">
                  <img
                    src={selected === k ? list_active : list_inactive}
                    className={`h-8 ${
                      selected === k ? "animate-fade" : "animate-fade"
                    }`}
                    alt=""
                  />
                </td>
                <td>
                  <span
                    className={` h-8 ${selected === k ? "text-[#29ABE2]" : ""}`}
                  >
                    {d}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default ListAnimed;
