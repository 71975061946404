import React, { useState } from "react";
import { ButtonComponent, ListAnimed } from "../../components";
import home_image from "../../assets/home_asset.png";

const Home = ({ onClickContact = () => {} }) => {
  return (
    <div className="w-screen lg:h-screen p-2 lg:p-0  z-20">
      <div className="flex flex-col justify-center items-center text-white h-full">
        <div className="flex flex-col lg:flex-row container items-center justify-center  mt-28">
          <div className=" flex-1">
            <p className="text-[48px] font-semibold">
              <span>Curating Data for High Quality AI Can Be Challenging</span>
            </p>
            <p className="text-[28px]">
              <span className="font-bold">
                Let us help you get high quality context out of your files and
                data.
              </span>
            </p>
            <div className="mt-10">
              <ListAnimed
                data={[
                  "Migrate Your Data",
                  "Index Your Files",
                  "Easily Search for Content",
                  "Curate and Label Your Data",
                  "Continuous Data Protection ",
                ]}
              />
            </div>
          </div>
          <div className=" flex-1">
            <img src={home_image} alt="" />
          </div>
        </div>
        <div className="flex flex-row container items-center justify-center ">
          <ButtonComponent
            text="SIGN UP & JOIN EARLY ACCESS"
            onClick={(t) => onClickContact(t)}
          />
        </div>
      </div>
    </div>
  );
};
export default Home;
